import React from "react";

const Price = () => {
  return (
    <div className="bg-gray-900 flex pt-10 flex-col items-center justify-center">
      <div className="w-3/5">
        <p className="text-[#7d4600] text-xl font-semibold">COURS DE LANGUES</p>

        <div className="text-[#7d4600] flex flex-col space-y-4 rounded-2xl mt-4 bg-white p-10">
          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Anglais - Débutant</span>
            <span>1h - 25&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Anglais - Intermédiaire</span>
            <span>1h - 30&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Anglais - Avancé</span>
            <span>1h - 35&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Allemand - Débutant</span>
            <span>1h - 25&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Allemand - Intermédiaire</span>
            <span>1h - 30&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Allemand - Avancé</span>
            <span>1h - 35&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Espagnol - Débutant</span>
            <span>1h - 25&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Espagnol - Intermédiaire</span>
            <span>1h - 30&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Espagnol - Avancé</span>
            <span>1h - 35&euro;</span>
          </p>
        </div>
      </div>

      <div className="w-3/5 my-6">
        <p className="text-[#7d4600] text-xl font-semibold">
          PACKS COURS DE LANGUES
        </p>

        <div className="text-[#7d4600] flex flex-col space-y-4 rounded-2xl mt-4 bg-white p-10">
          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Pack 5h - Anglais</span>
            <span>5h - 120&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Pack 5h - Allemand</span>
            <span>5h - 120&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Pack 5h - Espagnol</span>
            <span>5h - 120&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Pack 10h - Anglais</span>
            <span>10h - 230&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Pack 10h - Allemand</span>
            <span>10h - 230&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600] justify-between">
            <span>Pack 10h - Espagnol</span>
            <span>10h - 230&euro;</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Price;
