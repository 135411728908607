import Header from "./Header";
import {siteData} from './data';

const Mentions =  () => {


    return (
        <div>
            <Header />
            <div className="w-3/5 mx-auto">

            <p className="text-5xl mt-10 font-semibold  text-[#7d4600]">
                Mentions légales
            </p>
            <p className="my-4">
L'ensemble de ce site relève des législations françaises et internationales sur le droit d'auteur et la propriété intellectuelle.
            </p>

<p className="my-4">
L'utilisateur reconnaît avoir pris connaissance de la présente notice légale et s'engage à la respecter.
</p>

<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

1. Propriété du site
</p>
<p>

Le présent site est la propriété de Madame GIRARD Corinne
</p>

<p className="text-5xl my-4 font-semibold  text-[#7d4600]">
2. Informations relatives au site
</p>
<p>
Éditeur du site
</p>
<p>

{siteData.name}
</p>
<p>
{siteData.address}
 
</p>
<p>
{siteData.postCode} {siteData.town} 
</p>
<p>
Tél : {siteData.phone}

</p>
<p>
{siteData.urlSub}
</p>

<p>
Hébergement du site : Google
</p>

<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

3. Données personnelles
</p>
<p>

En conformité avec la Loi du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, le traitement automatisé des données nominatives réalisées à partir du site Internet {siteData.url} a fait l'objet d'une déclaration auprès de la Commission nationale de l'informatique et des libertés (CNIL) sous le n°CNIL
L'utilisateur est notamment informé que, conformément à l'Article 32 de la Loi "Informatique et Libertés", les informations communiquées par l'utilisateur du fait des formulaires présents sur le site, sont nécessaires pour répondre à sa demande et sont destinés à l'exploitant du site web, responsable du traitement à des fins de gestion administrative et commerciale, les informations transmises par les utilisateurs, à la seul expectation d'un demande légale de la part des instances judiciaires, ne sera jamais transmise à un tiers.
Les utilisateurs du site web {siteData.url} sont tenus de respecter les dispositions de la Loi "informatique et libertés", dont la violation est passible de sanctions pénales.
Ils doivent notamment s'abstenir, s'agissant des données nominatives auxquelles ils accèdent, de toute collecte, de toute utilisation détournée et, d'une manière générale, de tout acte susceptible de porter atteinte à la vie privée ou à la réputation de personnes.
</p>



<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

4. Propriété intellectuelle
</p>
<p>
Ce site est la propriété exclusive de {siteData.url}. Toute reproduction ou représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l'autorisation expresse de {siteData.url} est interdite et constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du Code de la propriété intellectuelle.
Les marques de {siteData.url} et de ses partenaires, ainsi que les logos figurant sur le site sont des marques (semi-figuratives ou non) et sont déposées. Toute reproduction totale ou partielle de ces marques ou de ces logos effectuées à partir des éléments du site sans l'autorisation expresse de {siteData.url} est prohibée, au sens des articles L.713-2 et suivants du Code de la propriété intellectuelle.
Si vous souhaitez reproduire un contenu (textes, graphiques, illustrations) paru sur le site " {siteData.url} " sur support papier et/ou électronique (Web, intranet, CD-ROM...), vous devez formuler votre demande par courrier à "ADRESSE" ou via courriel : Page de Contact
</p>



<p className="text-5xl my-4 font-semibold  text-[#7d4600]">
5. Configuration informatique
</p>
<p>

L'utilisateur du site {siteData.url} reconnaît disposer de la compétence et des moyens nécessaires pour accéder et utiliser le site.

L'utilisateur reconnaît avoir vérifié que la configuration informatique utilisée ne contient aucun virus et qu'elle est en parfait état de fonctionnement.
</p>


<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

6. Contenu du site
</p>
<p>

Les informations fournies par l'exploitant du site web le sont à titre indicatif. L'exploitant du site ne saurait garantir l'exactitude, la complétude, l'actualité des informations diffusées sur son site.
L'exploitant du site ne saurait par ailleurs être tenu pour responsable d'une indisponibilité des informations.
En conséquence, l'utilisateur reconnaît utiliser ces informations à titre strictement personnel et privé et sous sa responsabilité exclusive.
</p>


<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

7. Accessibilité du site
</p>
<p>

L'utilisateur reconnaît avoir été informé que le site web {siteData.url} est accessible 24h/24, 7j/7, à l'exception des cas de force majeure, difficultés informatiques, difficultés liées à la structure des réseaux de télécommunications ou difficultés techniques. Pour des raisons de maintenance, l'exploitant du site pourra interrompre son site et s'efforcera d'en avertir préalablement les utilisateurs.
</p>

<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

8. Cookies
</p>
<p>

L'utilisateur est informé que, lors de ses visites sur le site, un cookie peut s'installer automatiquement sur son logiciel de navigation. Le cookie est un bloc de données qui ne permet pas d'identifier les utilisateurs mais sert à enregistrer des informations relatives à la navigation de ceux-ci sur le site.
Le paramétrage du logiciel de navigation permet d'informer de la présence de cookies et l'utilisateur dispose du droit de s'opposer à l'enregistrement de cookies.
L'utilisateur est cependant informé que s'il refuse les cookies, certains services pourront lui être inaccessibles.
Si vous souhaitez exercer ce droit, vous pouvez suivre les instructions accessibles en ligne à l’adresse : https://www.cnil.fr/fr/comprendre-vos-droits
Les données collectées grâce aux cookies ne sont pas conservées.
Il existe deux types de cookies :
les cookies de session sont supprimés de votre ordinateur lorsque vous fermez votre navigateur.
les cookies permanents restent stockés sur votre ordinateur après la fermeture du navigateur.
Les cookies sont cependant anonymes et ne sont en aucun cas utilisés pour collecter des informations personnelles.
{siteData.url} utilise des cookies pour permettre et faciliter la communication par voie électronique, fournir les services en ligne souscrits par ses abonnés, analyser l'utilisation du site dans l’objectif d’améliorer la qualité de ses sites, collecter des données générales (non nominatives) sur ses clients à des fins de communication ciblée, y compris la fourniture d'informations ciblées sur les produits ou services fournis par {siteData.url}.
</p>







<p className="text-5xl my-4 font-semibold  text-[#7d4600]">
8.1. Refus des cookies

</p>
<p>

Vous êtes libre d'accepter ou de refuser les cookies en configurant votre navigateur (désactivation de tout ou partie des cookies - voir manuel de votre navigateur Web ou fonction d'aide).
La désactivation des cookies peut entraîner l’indisponibilité de certains services auxquels ils sont associés. Elle n'empêche pas l'accès aux espaces non-réservés du site.
Par ailleurs, nous gardons trace des connexions à nos sites, et notamment de leur origine. Cette utilisation est faite à des fins d'analyses statistiques. Les données correspondantes ne comportent pas de données personnelles, et sont détruites après usage.
</p>


<p className="text-5xl my-4 font-semibold  text-[#7d4600]">
9. Liens hypertextes

</p>
<p>

Les liens hypertextes présents sur le site et qui renvoient vers d'autres sites internet, notamment vers les sites partenaires, ont fait l'objet d'une autorisation préalable, expresse et écrite.
Si vous souhaitez mettre en place un hyperlien entre leur(s) site(s) et le site {siteData.url} vous en avez l'autorisation sans demande expresse, néanmoins le site {siteData.url} ne saurait en aucun cas être tenu pour responsable directement ou indirectement du contenu de ces sites.
</p>


<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

10. Service d'analyse d’audience
</p>
<p>

En vue d’adapter le o-beaute.com aux demandes de ses visiteurs, le nombre de visites, le nombre de pages vues ainsi que l’activité des visiteurs sur le site ainsi que leur fréquence de retour sont mesurés.
Nous utilisons ANALYTICS, un logiciel d'analyse d'audience open source configuré afin de respecter au mieux la vie privé des gens en conformité avec les recommandations de la CNIL.
Le logiciel génère un cookie avec un identifiant unique, dont la durée de conservation est limitée à 13 mois. L’outil utilisé collecte également votre adresse IP, qui est partiellement anonymisé dès son utilisation. Les données recueillies ne sont cédées à aucune tierce personne ou entité et ne sont pas utilisées à d’autres fins.
</p>




<p className="text-5xl my-4 font-semibold  text-[#7d4600]">

11. Sections commentaires
</p>
<p>

Le directeur de la publication du site n'est pas responsable des commentaires publiés sur {siteData.url} si il est établi qu'il n'avait pas connaissance du message avant sa mise en ligne.
Le directeur de la publication s'engage à agir promptement pour retirer tout message qu'il jugera inadapté ou pouvant porter atteinte à autrui dès connaissance du dit message.
</p>

        </div>
        </div>

    )
}

export default Mentions;