export const siteData = {
  "name": "VARLOO",
  "address": "17 RUE PAUL BERT",
  "postCode": "60290",
  "city": "GOUVIEUX",
  "phone": "0258986632",
  "url": "https://varloo.pro",
  "urlSub": "varloo.pro",
  "price": "price_1QKiglHBIkvwprbpC0mYvd7p",
  "pk": "pk_live_51QKiUiHBIkvwprbpQKAo5sN0QP1CIEDOla9sbSMXHnkFMideg70xhyX4kt9Bt0KdNNz5MCcBsf2T3COSzfAjxKtx00pLmwEePc",
  "sk": "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"
};