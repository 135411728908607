import HeroImg from "../assets/img/HeroImg.jpeg";
import { siteData } from './data';

const Hero = () => {
  return (
    <div>
      <div className="absolute top-96 font-bold text-2xl text-center w-full">
        <p>
          Bienvenue chez {siteData.name}, votre partenaire pour apprendre
          l&apos;anglais, l&apos;allemand, et bien plus encore!
        </p>
        <button className="text-sm bg-[#7d4600] px-4 py-2 mt-6 text-white rounded-md">
          Inscrivez-vous dès maintenant
        </button>
      </div>
      <img className="h-screen object-cover w-full" src={HeroImg} alt="Hero Background" />
    </div>
  );
};

export default Hero;
