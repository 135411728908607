import { Link } from "react-router-dom";
import {siteData} from './data';

const Header = () => {
  return (
    <div className="w-4/5 py-4 mx-auto flex items-center justify-between">
        <Link to={"/"}>
      <p className="text-[#7d4600] hover:cursor-pointer text-xl font-semibold">{siteData.name}</p>
        </Link>
      <div className="flex font-semibold text-[#7d4600] items-center space-x-4">
        <p className="hover:cursor-pointer">NOTRE EQUIPE</p>
        <p className="hover:cursor-pointer">DESCRIPTION</p>
        <p className="hover:cursor-pointer">NOTRE ADRESSE</p>
        <Link to={"checkout"}>
        <p className="rounded-lg p-2 hover:cursor-pointer text-white bg-[#7d4600]">
          RESERVER
        </p>
        </Link>
        <p className="rounded-lg p-2 hover:cursor-pointer bg-white text-[#7d4600]">
          MON COMPTE
        </p>
      </div>
    </div>
  );
};

export default Header;
